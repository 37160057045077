module services {
    export module tariff {
        export class procedureCategoryService implements interfaces.tariff.IProcedureCategoryService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(countryId: number, searchText?: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProcedureCategory/GetDropdownList", {
                    countryId: countryId,
                    searchText: searchText
                });
            }
        }
    }
    angular.module("app").service("procedureCategoryService", services.tariff.procedureCategoryService);
}